import gql from 'graphql-tag';

export const VERSION_HISTORY_TABLE_QUERY = gql`
  query versionHistoryTableQuery($benefitId: ID!) {
    flexBenefit(id: $benefitId) {
      id
      changes {
        id
        from
        to
        author
        createdAt
      }
    }
  }
`;
