import { EmploymentStatus } from '@frontend/utils';
import { ImportStatus } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const employeePensionMessages = defineMessages({
  deduction: {
    id: 'employeePensionMessages.deduction',
    description: 'Bruttolöneavdrag',
    defaultMessage: 'Bruttolöneavdrag',
  },
  premium: {
    id: 'employeePensionMessages.premium',
    description: 'Premie',
    defaultMessage: 'Premie',
  },
  provider: {
    id: 'employeePensionMessages.provider',
    description: 'Löneväxling via',
    defaultMessage: 'Via',
  },
  alertMessage: {
    id: 'employeePensionMessages.alertMessage',
    description: 'Den anställda har en förfrågan om löneväxling.',
    defaultMessage: 'Den anställda har en förfrågan om löneväxling.',
  },
  alertButton: {
    id: 'employeePensionMessages.alertButton',
    description: 'go to tasks',
    defaultMessage: 'Visa löneväxlingar',
  },
  premiumNeedsUpdate: {
    id: 'employeePensionMessages.premiumNeedsUpdate',
    description:
      'In order to activate pension for this employee, please update pension premium.',
    defaultMessage: `Ska den anställde ha insättningar till pension behöver du ange vilken månatlig premie som ska gälla.`,
  },
  futurPension: {
    id: 'employeePensionMessages.futurPension',
    description: 'Occupational pension autopilot',
    defaultMessage: 'Ålderspension Autopilot',
  },
  nordnetPension: {
    id: 'employeePensionMessages.nordnetPension',
    description: 'Occupational pension via Nordnet',
    defaultMessage: 'Ålderspension via Nordnet',
  },
});

export const employeeFormMessages = defineMessages({
  addEmployee: {
    id: 'employeeFormMessages.addEmployee',
    description: 'Lägg till anställd',
    defaultMessage: 'Lägg till anställd',
  },
  exportEmployees: {
    id: 'employeeFormMessages.exportEmployees',
    description: 'Export employees',
    defaultMessage: 'Exportera anställda',
  },
  addEmployeeDescription: {
    id: 'employeeFormMessages.addEmployeeDescription',
    description:
      'Lorem ipsum dolor sit amet, at fugit essent nam, mea ad nisl epicuri,eum impetus petentium erroribus ne. Id putent blandit repudiare pri,\n    ad partem facilisi eum. No sea adhuc forensibus contentiones, est ei\n    labore mediocritatem. Eam soleat suscipiantur in, sea ut nemore\n    fastidii similique.\n    ',
    defaultMessage:
      'Information om den anställda hämtas automatiskt från Statens personadressregister (SPAR). Den anställda får automatiskt tillgång till sin förmånsgrupps nuvarande och framtida förmåner.',
  },
  noPensionBenefits: {
    id: 'employeeFormMessages.noPensionBenefits',
    description: "This employee doesn't have any pension benefit",
    defaultMessage: 'Finns inga pensionsförsäkringar att visa',
  },
  noPensionBenefitsText: {
    id: 'employeeFormMessages.noPensionBenefitsText',
    description: "This employee doesn't have any pension benefit help text",
    defaultMessage:
      'När den anställde har en aktiv tjänstepension (exempelvis löneväxling) så kommer det att visas här.',
  },
  noInsuranceBenefits: {
    id: 'employeeFormMessages.noInsuranceBenefits',
    description: "This employee doesn't have any insurance benefit",
    defaultMessage: 'Det finns inga riskförsäkringar att visa',
  },
  noInsuranceBenefitsText: {
    id: 'employeeFormMessages.noInsuranceBenefitsText',
    description: "This employee doesn't have any insurance benefit helper text",
    defaultMessage:
      'När medarbetare läggs till i en förmånsgrupp med riskförsäkringar så kommer de att visas här.',
  },
  noStatus: {
    id: 'employeeFormMessages.noStatus',
    description:
      'Empty state message when a membership does not have any status',
    defaultMessage: `Inga händelser`,
  },
  editContactInformationTitle: {
    id: 'employeeFormMessages.editContactInformationTitle',
    description: 'Ändra {name}',
    defaultMessage: 'Ändra {name}',
  },
  editContactInformationDescription: {
    id: 'employeeFormMessages.editContactInformationDescription',
    description:
      'Du kan inte ändra namn på eller adress på en anställd eftersom\n    Vi hämtar den informationen från Statens personadressregister\n    (SPAR).',
    defaultMessage:
      'Vi hämtar automatiskt information om den anställdas namn och adress från Statens personadressregister (SPAR).',
  },
  addEmployeeBenefitPackagesDescription: {
    id: 'employeeFormMessages.addEmployeeBenefitPackagesDescription',
    description:
      'Den anställda kommer erbjudas alla nuvarande och framtida förmåner som hör till förmånsgruppen. Förmåner som inte behöver aktiveras av den anställda aktiveras automatiskt.',
    defaultMessage:
      'Den anställda får tillgång till förmånsgruppens nuvarande och framtida förmåner',
  },
  changeEmployeeBenefitPackagesDescription: {
    id: 'employeeFormMessages.changeEmployeeBenefitPackagesDescription',
    description:
      'Vid byte av förmånsgrupp avslutas nuvarande förmåner. Den anställda får istället tillgång till den nya förmånsgruppens förmåner. Var uppmärksam på att detta kan leda till att riskförsäkringar avslutas för den anställda.',
    defaultMessage:
      'Vid byte av förmånsgrupp avslutas nuvarande förmåner. Den anställda får istället tillgång till den nya förmånsgruppens förmåner. Var uppmärksam på att detta kan leda till att riskförsäkringar avslutas för den anställda.',
  },
  updateEmployeeSalaryTitle: {
    id: 'employeeFormMessages.updateEmployeeSalaryTitle',
    description: 'Ändra lön för {name}',
    defaultMessage: 'Ändra lön för {name}',
  },
  updateEmployeeSalaryDescription: {
    id: 'employeeFormMessages.updateEmployeeSalaryDescription',
    description:
      'The salary updates from the first of the month. Salary can be changed from the earliest registered one.',
    defaultMessage:
      'Ändring av lön gäller från och med den första i månaden. Lön kan ändras tidigast från och med redan registrerad lön.',
  },
  updateEmployeePensionPremiumDescription: {
    id: 'employeeFormMessages.updateEmployeePensionPremiumDescription',
    description: 'Description in modal for updating pension premium value.',
    defaultMessage:
      'Ändring av pensionspremie gäller från och med den första i månaden. Du kan ändra premie upp till en månad framåt och tre månader bakåt i tiden.',
  },
  addEmployeeStatus: {
    id: 'employeeFormMessages.addEmployeeStatus',
    description: 'Add membership status',
    defaultMessage: 'Lägg till status',
  },
  updateEmployeeStatus: {
    id: 'employeeFormMessages.updateEmployeeStatus',
    description: 'Ändra status',
    defaultMessage: 'Ändra status',
  },
  updateEmployeeStatusForTitle: {
    id: 'employeeFormMessages.updateEmployeeStatusForTitle',
    description: 'Ändra status för {name}',
    defaultMessage: 'Ändra status för {name}',
  },
  noEmployeeMatch: {
    id: 'employeeFormMessages.noEmployeeMatch',
    description: 'No employees match the search',
    defaultMessage: 'Inga personer matchar sökningen',
  },
  updateRole: {
    id: 'employeeFormMessages.updateRole',
    description: 'update membershp role',
    defaultMessage: 'Ändra systemrättigheter',
  },
  updateRoleDescription: {
    id: 'employeeFormMessages.updateRoleDescription',
    description: 'The description of updating membership role.',
    defaultMessage: `För att ge andra tillgång att sköta företaget, kan du tilldela vissa systemrättigheter till andra administratörer. För att byta huvudadministratör, kontakta kundtjänst.`,
  },
  confirmSubmitAddEmployee: {
    id: 'employeeFormMessages.confirmSubmitAddEmployee',
    description:
      'Label for checkbox to confirm all details is correct when adding an employee',
    defaultMessage: `Härmed intygar jag att samtliga uppgifter enligt ovan är sanningsenliga och korrekta. Jag bekräftar att ovanstående anställd ska omfattas av de avtal som arbetsgivaren ingått med Nordea Node AB, och i förekommande fall med med de försäkringsbolag som avtal ingåtts med genom Nordea Nodes Portal.`,
  },
  confirmImport: {
    id: 'employeeFormMessages.confirmImport',
    description: 'Confirm import',
    defaultMessage: 'Bekräfta import',
  },
  confirmWelcomeEmails: {
    id: 'employeeFormMessages.confirmWelcomeEmails',
    description: 'Skip welcome emails',
    defaultMessage: 'Skicka e-post',
  },
  confirmSkipWelcomeEmails: {
    id: 'employeeFormMessages.confirmSkipWelcomeEmails',
    description:
      'Label for checkbox to confirm whether welcome emails should be sent or not',
    defaultMessage: `Skicka e-post med information om Nordea Node till nyanställda`,
  },
  confirmSendWelcomeEmail: {
    id: 'employeeFormMessages.confirmSendWelcomeEmail',
    description:
      'Label for checkbox to confirm whether welcome emails should be sent or not',
    defaultMessage: `Skicka e-post med information om Nordea Node`,
  },
  confirmSubmitImportEmployees: {
    id: 'employeeFormMessages.confirmSubmitImportEmployees',
    description:
      'Label for checkbox to confirm all details is correct when importing employees',
    defaultMessage: `Härmed intygar jag att samtliga uppgifter är sanningsenliga och korrekta. Jag bekräftar att anställda i förekommande fall ska omfattas av de tjänstepensionsförsäkringar och riskförsäkringar som bolaget ingått avtal med genom Nordea Node AB.`,
  },
  effectiveDateStatusHelperText: {
    id: 'employeeFormMessages.effectiveDateStatusHelperText',
    description: 'Helper text for effective date when handling statuses',
    defaultMessage: 'Startdatum för denna händelse',
  },
  effectiveUntilStatusHelperText: {
    id: 'employeeFormMessages.effectiveUntilStatusHelperText',
    description: 'Helper text for effective until when handling statuses',
    defaultMessage: 'Slutdatum för denna händelse',
  },
  membershipStatusExtentHelperText: {
    id: 'employeeFormMessages.membershipStatusExtentHelperText',
    description: 'Helper text for extent when handling statuses',
    defaultMessage:
      'För deltidsanställningar, ange andelen av en heltidstjänst',
  },
  updateMembershipOrgUnitDescription: {
    id: 'employeeFormMessages.updateMembershipOrgUnitDescription',
    description: 'Description in update organizational unit modal',
    defaultMessage: 'Välj ny avdelning',
  },
  showTerminatedEmployees: {
    id: 'employeeFormMessages.showTerminatedEmployees',
    description: 'Switch label, show terminated employees',
    defaultMessage: 'Visa personer utan anställning',
  },
  confirmSwitchBackageTitle: {
    id: 'employeeFormMessages.confirmSwitchBackageTitle',
    defaultMessage: 'Byta förmånsgrupp?',
  },
  confirmSwitchBackage: {
    id: 'employeeFormMessages.confirmSwitchBackage',
    defaultMessage:
      'Förmånsgruppen för den anställda kommer att uppdateras till {backageName} fr.o.m. {effectiveDate}.',
  },
  confirmSwitchOrganisationTitle: {
    id: 'employeeFormMessages.confirmSwitchOrganisationTitle',
    defaultMessage: 'Byta avdelning?',
  },
  confirmSwitchOrganisation: {
    id: 'employeeFormMessages.confirmSwitchOrganisation',
    defaultMessage:
      'Avdelning kommer att ändras för den anställda och börjar gälla direkt.',
  },
  confirmUpdateSalary: {
    id: 'employeeFormMessages.confirmUpdateSalary',
    defaultMessage:
      'Lön kommer att ändras till {monthlySalary} fr.o.m {effectiveMonth} för den anställda. Är du säker?',
  },
  nidOptOut: {
    id: 'employeeFormMessages.nidOptOut',
    defaultMessage:
      'Lägg till anställd utan svenskt personnummer eller samordningsnummer',
  },
  birthdate: {
    id: 'employeeFormMessages.birthdate',
    defaultMessage: 'Födelsedatum',
  },
  sex: {
    id: 'employeeFormMessages.sex',
    defaultMessage: 'Kön',
  },
  address: {
    id: 'employeeFormMessages.address',
    defaultMessage: 'Gatuadress',
  },
  city: {
    id: 'employeeFormMessages.city',
    defaultMessage: 'Stad',
  },
  female: {
    id: 'employeeFormMessages.female',
    defaultMessage: 'Kvinna',
  },
  male: {
    id: 'employeeFormMessages.male',
    defaultMessage: 'Man',
  },
  nidDescription: {
    id: 'employeeFormMessages.nidDescription',
    defaultMessage:
      'Du kan lägga till fler identifierare. Vi har stöd för personnummer, samordningsnummer och A-nummer.',
  },
  addNid: {
    id: 'employeeFormMessages.addNid',
    defaultMessage: 'Lägg till identifierare',
  },
  employeeIsUnder18: {
    id: 'employeeFormMessages.employeeIsUnder18',
    defaultMessage: 'Den anställde är under 18 år',
  },
  employeeIsOver62: {
    id: 'employeeFormMessages.employeeIsOver62',
    defaultMessage: 'Den anställde är över 62 år',
  },
  confirmEmployeeAgeTitle: {
    id: 'employeeFormMessages.confirmEmployeeAgeTitle',
    defaultMessage: 'Har korrekt ålder angivits?',
  },
  confirmEmployeeIsUnder18: {
    id: 'employeeFormMessages.confirmEmployeeIsUnder18',
    defaultMessage: 'Bekräfta att den anställde är under 18 år.',
  },
  confirmEmployeeIsOver62: {
    id: 'employeeFormMessages.confirmEmployeeIsOver62',
    defaultMessage: 'Bekräfta att den anställde är över 62 år.',
  },
  activeEmployment: {
    id: 'employeeFormMessages.activeEmployment',
    defaultMessage: 'Aktiv anställning',
  },
});

export const employmentMessages = defineMessages({
  employment: {
    id: 'employmentMessages.employment',
    description: 'No employments exists',
    defaultMessage: 'Anställning',
  },
  partTimeEmployment: {
    id: 'common.partTimeEmployment',
    description: 'Part-time employment',
    defaultMessage: 'Sysselsättningsgrad',
  },
  noEmployments: {
    id: 'employmentMessages.noEmployments',
    description: 'No employments exists',
    defaultMessage: 'Det finnas inga anställningar att visa',
  },
  employmentStartDate: {
    id: 'employmentMessages.employmentStartDate',
    description: 'Employment start date',
    defaultMessage: 'Anställningsdatum',
  },
  employmentEndDate: {
    id: 'employmentMessages.employmentEndDate',
    description: 'Employment end date',
    defaultMessage: 'Avgångsdatum',
  },
  addEmployment: {
    id: 'employmentMessages.addEmployment',
    description: 'Add employment',
    defaultMessage: 'Lägg till anställning',
  },
  editEmployment: {
    id: 'employmentMessages.editEmployment',
    description: 'Edit employment',
    defaultMessage: 'Ändra anställning ',
  },
  addEmploymentForName: {
    id: 'employmentMessages.addEmploymentForName',
    description:
      'Message saying you can add employment for an employee, takes {employeeName} as value.',
    defaultMessage: 'Lägg till anställning för {employeeName}',
  },
  editEmploymentForName: {
    id: 'employmentMessages.editEmploymentForName',
    description:
      'Message saying you can edit employment for an employee, takes {employeeName} as value.',
    defaultMessage: 'Ändra anställning för {employeeName}',
  },
  absenceAlternatives: {
    id: 'employmentMessages.absenceAlternatives',
    defaultMessage: 'Alternativ för frånvaro',
  },
  removeAbsence: {
    id: 'employmentMessages.removeAbsence',
    defaultMessage: 'Ta bort frånvaro',
  },
  editAbsence: {
    id: 'employmentMessages.editAbsence',
    defaultMessage: 'Redigera frånvaro',
  },
  absenceRemovalSuccessful: {
    id: 'employmentMessages.absenceRemovalSuccessful',
    defaultMessage: 'Frånvaron togs bort',
  },
  absenceRemovalFailure: {
    id: 'employmentMessages.absenceRemovalFailure',
    defaultMessage: 'Frånvaron kunde inte tas bort',
  },
  confirmDeleteEmployment: {
    id: 'employmentMessages.confirmDeleteEmployment',
    description: 'Confirm message shown before deleting an employment',
    defaultMessage: 'Vill du verkligen ta bort anställningen?',
  },
  confirmDeleteEmploymentActivityTitle: {
    id: 'employmentMessages.confirmDeleteEmploymentActivityTitle',
    defaultMessage: 'Ta bort frånvaro?',
  },
  confirmDeleteEmploymentActivity: {
    id: 'employmentMessages.confirmDeleteEmploymentActivity',
    description: 'Confirm message shown before deleting an employment activity',
    defaultMessage: `{type, select,
      OFF_DUTY {Tjänstledigheten}
      PARENTAL_LEAVE {Föräldraledigheten}
      other {Frånvaron}
    } kommer att tas bort och den anställdes försäkringsskydd kan påverkas.`,
  },
  addEmploymentActivity: {
    id: 'employmentMessages.addEmploymentActivity',
    description: 'Add employment activity',
    defaultMessage: 'Lägg till frånvaro',
  },
  addEmploymentActivityForName: {
    id: 'employmentMessages.addEmploymentActivityForName',
    description:
      'Message saying you can add employment activity for an employee, takes {employeeName} as value',
    defaultMessage: 'Lägg till frånvaro för {employeeName}',
  },
  editEmploymentActivityForName: {
    id: 'employmentMessages.editEmploymentActivityForName',
    description:
      'Message saying you can edit employment activity for an employee, takes {employeeName} and {type} as values',
    defaultMessage: `Ändra {type, select,
      OFF_DUTY {tjänstledighet}
      PARENTAL_LEAVE {föräldraledighet}
      other {frånvaron}
    } för {employeeName}`,
  },
  employmentActivity: {
    id: 'employmentMessages.employmentActivity',
    description: 'Employment activity',
    defaultMessage: 'Frånvaro',
  },
  employmentActivityRate: {
    id: 'employmentMessages.employmentActivityRate',
    description: 'Employment activity rate',
    defaultMessage: 'Frånvaro omfattning',
  },
  employmentActivityRateLabel: {
    id: 'employmentMessages.employmentActivityRateLabel',
    description: 'Employment activity rate: {rate}',
    defaultMessage: 'Frånvaro omfattning: {rate}',
  },
  employmentActivityEffectiveDate: {
    id: 'employmentMessages.employmentActivityEffectiveDate',
    description: 'Employment activity effective date',
    defaultMessage: 'Frånvaro gäller fr.o.m.',
  },
  employmentActivityEffectiveUntil: {
    id: 'employmentMessages.employmentActivityEffectiveUntil',
    description: 'Employment activity effective until',
    defaultMessage: 'Frånvaro gäller t.o.m.',
  },
  noEmploymentActivities: {
    id: 'employmentMessages.noEmploymentActivities',
    description: 'No employment activities for this employment',
    defaultMessage: 'Det finns ingen frånvaro kopplad till denna anställning',
  },
  employmentActivityType: {
    id: 'employmentMessages.employmentActivityType',
    description: 'Employment activity type',
    defaultMessage: 'Typ av frånvaro',
  },
  deleteEmployment: {
    id: 'employmentMessages.deleteEmployment',
    defaultMessage: 'Ta bort anställning',
  },
  deleteEmploymentForName: {
    id: 'employmentMessages.deleteEmploymentForName',
    defaultMessage: 'Ta bort anställning för {employeeName}',
  },
  deleteEmploymentDesc: {
    id: 'employmentMessages.deleteEmploymentDesc',
    defaultMessage:
      'Anställningen kommer att tas bort. Den anställde kommer mista rätten till förmåner för denna anställning. Vill du fortsätta?',
  },
  deleteEmploymentWarning: {
    id: 'employmentMessages.deleteEmploymentWarning',
    defaultMessage:
      'Medarbetaren kommer anses anställd under minst en dag och kan därför fortfarande ha rätt till försäkringar och förmåner. Om du vill avsluta anställningen från start använd "{deleteEmploymentMsg}".',
  },
  contactToDeleteEmployment: {
    id: 'employmentMessages.contactToDeleteEmployment',
    defaultMessage:
      'Medarbetaren kommer anses anställd under minst en dag och kan därför fortfarande ha rätt till försäkringar och förmåner. Om du vill avsluta anställningen från start så behöver du kontakta kundtjänst.',
  },
});

export const employmentStatusMessages = defineMessages<EmploymentStatus>({
  ACTIVE: {
    id: 'employmentStatusMessages.ACTIVE',
    defaultMessage: 'I tjänst',
  },
  OFF_DUTY: {
    id: 'employmentStatusMessages.OFF_DUTY',
    defaultMessage: 'Tjänstledig',
  },
  PARENTAL_LEAVE: {
    id: 'employmentStatusMessages.PARENTAL_LEAVE',
    defaultMessage: 'Föräldraledig',
  },
  TERMINATED: {
    id: 'employmentStatusMessages.TERMINATED',
    defaultMessage: 'Avslutad anställning',
  },
});

export const employeeMessages = defineMessages({
  search: {
    id: 'employees.search',
    defaultMessage: 'Sök anställd',
  },
  searchDescription: {
    id: 'employees.searchDescription',
    defaultMessage:
      'För att se information om en anställd, sök på namn, personnummer eller User account ID.',
  },
  employees: {
    id: 'employees.employees',
    defaultMessage: 'Anställda',
  },
  noEmployeeMatch: {
    id: 'employees.noEmployeeMatch',
    defaultMessage: 'Ingen anställd hittades',
  },
  noEmployeeMatchForSearch: {
    id: 'employees.noEmployeeMatchForSearch',
    defaultMessage: 'Ingen anställd hittades för sökningen "{search}"',
  },
  impersonate: {
    id: 'employees.impersonate',
    defaultMessage: 'Impersonate',
  },
});

export const employeesImportMessages = defineMessages({
  balanceEffectiveDate: {
    id: 'balanceEffectiveDate.balanceEffectiveDate',
    defaultMessage: '{customType} datum',
  },
  benefitPackageEffectiveDateDescription: {
    id: 'employeesImportMessages.benefitPackageEffectiveDateDescription',
    defaultMessage:
      'Kolumnen ”Förmånsgrupp fr.o.m.” bestämmer när en medarbetare tilldelas en förmånsgrupp. Om värdet saknas gäller istället det förvalda värdet. För nyanställda kan du ange att anställningsdatumet ska vara förval.',
  },
  benefitPackageEffectiveDateTitle: {
    id: 'employeesImportMessages.benefitPackageEffectiveDateTitle',
    defaultMessage: 'Förval för förmånsgrupp',
  },
  benefitsStartWithEmploymentsLabel: {
    id: 'employeesImportMessages.benefitsStartWithEmploymentsLabel',
    defaultMessage: 'Använd anställningsdatum som förval för nyanställda',
  },
  column: {
    id: 'employeesImportMessages.column',
    defaultMessage: 'Kolumn',
  },
  createMissingOrgUnits: {
    id: 'employeesImportMessages.createMissingOrgUnits',
    defaultMessage:
      'Skapa avdelningar automatiskt baserat på värden i kolumn för Avdelningar',
  },
  downloadFile: {
    id: 'employeesImportMessages.downloadFile',
    defaultMessage: 'Ladda ned fil',
  },
  downloadTemplate: {
    id: 'employeesImportMessages.downloadTemplate',
    defaultMessage: 'Ladda ned vår mall',
  },
  employeeStatus: {
    id: 'employeesImportMessages.employeeStatus',
    defaultMessage: 'förändring',
  },
  emptyStateTitle: {
    id: 'employeesImportMessages.emptyStateTitle',
    defaultMessage: 'Inga förändringar',
  },
  emptyStateDescription: {
    id: 'employeesImportMessages.emptyStateDescription',
    defaultMessage:
      'Det finns inga förändringar i filen du laddat upp.\n Pröva att matcha kolumner igen.',
  },
  emptyStateButtonText: {
    id: 'employeesImportMessages.emptyStateButtonText',
    defaultMessage: 'Matcha Kolumner',
  },
  errorTableErrorColumn: {
    id: 'importMessages.errorTableErrorColumn',
    defaultMessage: 'Fel',
  },
  errorTableRowColumn: {
    id: 'importMessages.errorTableRowColumn',
    defaultMessage: 'Rad',
  },
  fallbackBenefitPackageEffectiveDateHelperText: {
    id: 'employeesImportMessages.fallbackBenefitPackageEffectiveDateHelperText',
    defaultMessage: 'Används endast om värde saknas för en medarbetare',
  },
  fallbackBenefitPackageEffectiveDateLabel: {
    id: 'employeesImportMessages.fallbackBenefitPackageEffectiveDateLabel',
    defaultMessage: 'Förvalt värde för ”Förmånsgrupp fr.o.m.”',
  },
  field: {
    id: 'employeesImportMessages.field',
    defaultMessage: 'Fält',
  },
  fileUploadDescription: {
    id: 'employeesImportMessages.fileUploadDescription',
    defaultMessage: 'Systemet förstår Excel- och CSV-filer.',
  },
  fileUploadError: {
    id: 'employeesImportMessages.fileUploadError',
    defaultMessage: 'Filen kunde inte laddas upp',
  },
  fileUploadTitle: {
    id: 'employeesImportMessages.fileUploadTitle',
    defaultMessage: 'Dra och släpp fil här',
  },
  generateColumnChangeDescription: {
    id: 'employeesImportMessages.generateColumnChangeDescription',
    defaultMessage: 'Du skickas vidare till kolumnmappning...',
  },
  generateChangesTitle: {
    id: 'employeesImportMessages.generateChangesTitle',
    defaultMessage: 'Vi ser över dina ändringar!',
  },
  generateChangeDescription: {
    id: 'employeesImportMessages.generateChangeDescription',
    defaultMessage: 'Du skickas vidare till översikten...',
  },
  importColumnsError: {
    id: 'employeesImportMessages.importColumnsError',
    defaultMessage: 'Fälten för importfilen kunde inte läsas',
  },
  importEmployees: {
    id: 'employeesImportMessages.importEmployees',
    defaultMessage: 'Importera anställda',
  },
  importEmployeesTo: {
    id: 'employeesImportMessages.importEmployeesTo',
    defaultMessage: 'Importera anställda till {companyName} ({regNbr})',
  },
  importFile: {
    id: 'employeesImportMessages.importFile',
    defaultMessage: 'Importera fil',
  },
  importStatus: {
    id: 'employeesImportMessages.importStatus',
    defaultMessage: 'Importstatus',
  },
  inactiveEmploymentsLabel: {
    id: 'employeesImportMessages.inactiveEmploymentsLabel',
    defaultMessage: `Importen innehåller ändringar för medarbetare som saknar aktiv anställning för det angivna datumet`,
  },
  inactiveEmploymentsLede: {
    id: 'employeesImportMessages.inactiveEmploymentsLede',
    defaultMessage: `
      <p>Dessa medarbetare har ej en aktiv anställning för en eller flera förändringar som finns i importen. Importen kommer genomföra dessa förändringar även för medarbetare med inaktiva anställningar. Ändringen påverkar dock inte några pensions- eller riskförsäkringar eftersom att det inte finns en aktiv anställning för det valda datumet och därmed inga försäkringar.</p>
      <p>Om personen är felaktigt avslutad i systemet, se över start- och slutdatum under Anställda. Du bör även se över systemet som skapar import-filen så att rätt datum är angivet.</p>
    `,
  },
  invalidColumnsError: {
    id: 'employeesImportMessages.invalidColumnsError',
    defaultMessage:
      'En eller flera kolumner är inte korrekt matchade. Rader med fel är markerade. Vänligen försök igen.',
  },
  missingEmploymentsLabel: {
    id: 'employeesImportMessages.missingEmploymentsLabel',
    defaultMessage: `Importen innehåller inte information om alla medarbetare med en aktiv anställning på företaget`,
  },
  missingEmploymentsLede: {
    id: 'employeesImportMessages.missingEmploymentsLede',
    defaultMessage: `<p>Dessa medarbetare har en aktiv anställning men finns inte med i denna import. Om en anställning inte längre ska vara aktiv behöver ett slutdatum sättas för att avsluta anställningen och dess eventuella förmåner. Att enbart genomföra en import utan en medarbetare avslutar inte anställningen i systemet</p><p>Om du vill ange ett slutdatum gör du det under Anställda.</p>`,
  },
  noEmployeesImportedText: {
    id: 'employeesImportMessages.noEmployeesImportedText',
    defaultMessage: 'Du har inte ännu importerat några anställda.',
  },
  noImports: {
    id: 'employeesImportMessages.noImports',
    defaultMessage: 'Inga importer',
  },
  overlappingEmploymentsLabel: {
    id: 'employeesImportMessages.overlappingEmploymentsLabel',
    defaultMessage: `Importen innehåller anställda som redan har en aktiv anställning på ett annat bolag`,
  },
  overlappingEmploymentsLede: {
    id: 'employeesImportMessages.overlappingEmploymentsLede',
    defaultMessage: `<p>Det kan finnas giltiga skäl till att medarbetare har aktiva anställningar i mer än ett bolag i  systemet. Säkerställ att detta är enligt förväntan. Om medarbetare har bytt anställning från ett bolag till ett annat behöver ett slutdatum sättas i det tidigare bolaget för att avsluta anställningen och dess eventuella förmåner.</p><p>Om du vill ange ett slutdatum gör du det under Anställda.</p>`,
  },
  previewPageDescription: {
    id: 'employeesImportMessages.previewPageDescription',
    defaultMessage:
      'Följande information kommer att importeras. Vänligen bekräfta och godkänn för att importera.',
  },
  previewTableTitle: {
    id: 'employeesImportMessages.previewTableTitle',
    defaultMessage: 'Genomförda ändringar',
  },
  previewTableTitlePending: {
    id: 'employeesImportMessages.previewTableTitlePending',
    defaultMessage: 'Ändringar',
  },
  previewTitleEmployees: {
    id: 'employeesImportMessages.previewTitleEmployees',
    defaultMessage: 'Anställningar',
  },
  previewTitleEmploymentActivity: {
    id: 'employeesImportMessages.previewTitleEmploymentActivity',
    defaultMessage: 'Ny frånvaro',
  },
  previewTitleNewEmployees: {
    id: 'employeesImportMessages.previewTitleNewEmployees',
    defaultMessage: 'Nyanställda',
  },
  previewTitleSalaryUpdates: {
    id: 'employeesImportMessages.previewTitleSalaryUpdates',
    defaultMessage: 'Löneändringar',
  },
  previewTitleTerminated: {
    id: 'employeesImportMessages.previewTitleTerminated',
    defaultMessage: 'Avslutade',
  },
  previewTitleUnchanged: {
    id: 'employeesImportMessages.previewTitleUnchanged',
    defaultMessage: 'Oförändrade rader',
  },
  quantity: {
    id: 'employeesImportMessages.quantity',
    defaultMessage: 'Antal',
  },
  remunerationEffectiveDate: {
    id: 'employeesImportMessages.remunerationEffectiveDate',
    defaultMessage: '{customType} gäller fr.o.m.',
  },
  selectFile: {
    id: 'employeesImportMessages.selectFile',
    defaultMessage: 'Välj fil',
  },
  skipImport: {
    id: 'employeesImportMessages.skipImport',
    defaultMessage: 'Importera inte',
  },
  statusFailureWithCount: {
    id: 'employeesImportMessages.statusFailureWithCount',
    defaultMessage: 'Importfel ({count})',
  },
  newEmployee: {
    id: 'employeesImportMessages.newEmployee',
    defaultMessage: 'Nyanställd',
  },
  terminatedEmployee: {
    id: 'employeesImportMessages.terminatedEmployee',
    defaultMessage: 'Avslutad',
  },
  toOverView: {
    id: 'employeesImportMessages.toOverView',
    defaultMessage: 'Till översikt',
  },
  updateColumnsDescription: {
    id: 'employeesImportMessages.updateColumnsDescription',
    defaultMessage:
      'Ange hur kolumnerna i filen ska importeras. Välj "Importera inte" om du vill hoppa över en kolumn.',
  },
  updateColumnsError: {
    id: 'employeesImportMessages.updateColumnsError',
    defaultMessage:
      'Någonting gick fel. Vänligen säkerställ att kolumnerna är rätt matchade.',
  },
  updatedEmployee: {
    id: 'employeesImportMessages.updatedEmployee',
    defaultMessage: 'Ändrad',
  },
  updateErrorDescription: {
    id: 'updateErrorDescription.updateErrorDescription',
    defaultMessage:
      'En eller flera rader kunde inte importeras från filen, rader med fel är markerade. Vänligen försök igen eller pröva med en annan fil.',
  },
  updateLoadingTitle: {
    id: 'updateErrorDescription.updateLoadingTitle',
    defaultMessage: 'Dina anställda läggs till nu!',
  },
  updateLoadingDescription: {
    id: 'updateErrorDescription.updateLoadingDescription',
    defaultMessage: 'Du skickas tillbaka till översikten...',
  },
  updateSuccessDescription: {
    id: 'updateSuccessDescription.updateSuccessDescription',
    defaultMessage: 'Filen har importerats utan fel',
  },
  uploadFile: {
    id: 'employeesImportMessages.uploadFile',
    defaultMessage: 'Ladda upp',
  },
  newerImportWithChanges: {
    id: 'importMessages.newerImportWithChanges',
    defaultMessage:
      'Importen kan inte godkännas eftersom <newerImportWithChangesHref>följande import är skapad senare</newerImportWithChangesHref> med status "Väntar"',
  },
});

export const employeesImportStatusMessages = defineMessages<ImportStatus>({
  CANCELED: {
    id: 'employeesImportStatusMessages.CANCELED',
    defaultMessage: 'Avbruten',
  },
  COMPLETE: {
    id: 'employeesImportStatusMessages.COMPLETE',
    defaultMessage: 'Importerad',
  },
  FAILURE: {
    id: 'employeesImportStatusMessages.FAILURE',
    defaultMessage: 'Importfel',
  },
  PENDING: {
    id: 'employeesImportStatusMessages.PENDING',
    defaultMessage: 'Väntar',
  },
  PROCESSING: {
    id: 'employeesImportStatusMessages.PROCESSING',
    defaultMessage: 'Bearbetas...',
  },
  WORKING: {
    id: 'employeesImportStatusMessages.WORKING',
    defaultMessage: 'Import pågår',
  },
  NO_CHANGES: {
    id: 'employeesImportStatusMessages.NO_CHANGES',
    defaultMessage: 'Inga ändringar',
  },
});

export const membershipEventHistoryMessages = defineMessages({
  eventHistory: {
    id: 'membershipEventHistoryMessages.eventHistory',
    defaultMessage: 'Händelsehistorik',
  },
  event: {
    id: 'membershipEventHistoryMessages.event',
    defaultMessage: 'Händelse',
  },
  reportingDate: {
    id: 'membershipEventHistoryMessages.reportingDate',
    defaultMessage: 'Rapporteringsdatum',
  },
  source: {
    id: 'membershipEventHistoryMessages.source',
    defaultMessage: 'Källa',
  },
  author: {
    id: 'membershipEventHistoryMessages.author',
    defaultMessage: 'Aktör',
  },
  fileImport: {
    id: 'membershipEventHistoryMessages.fileImport',
    defaultMessage: 'Filimport',
  },
  portal: {
    id: 'membershipEventHistoryMessages.portal',
    defaultMessage: 'Portal',
  },
});

export const workInformationMessages = defineMessages({
  ownerLabel: {
    id: 'workInformationMessages.ownerLabel',
    description: 'Position',
    defaultMessage: 'Ägarposition',
  },
  owner: {
    id: 'workInformationMessages.owner',
    description: 'Owns at least a third of company stock',
    defaultMessage: 'Äger minst en tredjedel av företagets aktier',
  },
  personStatusLabel: {
    id: 'workInformationMessages.personStatusLabel',
    description: 'Status',
    defaultMessage: 'Anställningsstatus',
  },
  collectiveAgreementLabel: {
    id: 'workInformationMessages.collectiveAgreementLabel',
    description: 'Kollektivavtal',
    defaultMessage: 'Kollektivavtal',
  },
  employeeNumberLabel: {
    id: 'workInformationMessages.employeeNumberLabel',
    description: 'Anställningsnummer',
    defaultMessage: 'Anställningsnummer',
  },
  vacationDaysPerYearLabel: {
    id: 'workInformationMessages.vacationDaysPerYearLabel',
    description: 'Semesterrätt',
    defaultMessage: 'Semesterrätt',
  },
  vacationDaysSavedLabel: {
    id: 'workInformationMessages.vacationDaysSavedLabel',
    description: 'Sparade semesterdagar',
    defaultMessage: 'Sparade semesterdagar',
  },
  vacationDaysPaidLabel: {
    id: 'workInformationMessages.vacationDaysPaidLabel',
    description: 'Betalda semesterdagar',
    defaultMessage: 'Betalda semesterdagar',
  },
  companyCarMonthlyValueLabel: {
    id: 'workInformationMessages.companyCarMonthlyValueLabel',
    description: 'Förmånsvärde tjänstebil',
    defaultMessage: 'Förmånsvärde tjänstebil',
  },
  fitnessContributionBalanceLabel: {
    id: 'workInformationMessages.fitnessContributionBalanceLabel',
    description: 'Fitness contribution balance',
    defaultMessage: 'Friskvårdssaldo',
  },
  fitnessContributionBalanceHelperText: {
    id: 'workInformationMessages.fitnessContributionBalanceHelperText',
    description: 'Fitness contribution balance helper text',
    defaultMessage: 'Friskvårdssaldo visas i den anställdas förmånsportal',
  },
  fitnessContributionBalanceDescText: {
    id: 'workInformationMessages.fitnessContributionBalanceDescText',
    description: 'Fitness contribution balance description text',
    defaultMessage:
      'Om ni har friskvård via Epassi, kontrolleras friskvårdssaldo av er friskvårdspolicy.',
  },
  vacationDaysUnpaidLabel: {
    id: 'workInformationMessages.vacationDaysUnpaidLabel',
    description: 'Obetalda semesterdagar',
    defaultMessage: 'Obetalda semesterdagar',
  },
  vacationDaysAdvanceLabel: {
    id: 'workInformationMessages.vacationDaysAdvanceLabel',
    description: 'Vacation days in advance',
    defaultMessage: 'Förskottssemester',
  },
  employerContributionShareLabel: {
    id: 'workInformationMessages.employerContributionShareLabel',
    description: 'Arbetsgivaravgifter (%)',
    defaultMessage: 'Arbetsgivaravgifter (%)',
  },
  incomeTaxTableLabel: {
    id: 'workInformationMessages.incomeTaxTableLabel',
    description: 'Skattetabell',
    defaultMessage: 'Skattetabell',
  },
  costCenterLabel: {
    id: 'workInformationMessages.costCenterLabel',
    description: 'Kostnadsställe',
    defaultMessage: 'Kostnadsställe',
  },
  currentCostCenter: {
    id: 'workInformationMessages.currentCostCenter',
    description: 'Tidigare kostnadsställe',
    defaultMessage: 'Nuvarande kostnadsställe',
  },
  newCostCenter: {
    id: 'workInformationMessages.newCostCenter',
    description: 'Nytt kostnadsställe',
    defaultMessage: 'Välj nytt kostnadsställe',
  },
  monthlyDeposit: {
    id: 'workInformationMessages.monthlyDeposit',
    description: 'Månadsavsättning',
    defaultMessage: 'Månadsavsättning',
  },
  currentMonthlySalary: {
    id: 'workInformationMessages.currentMonthlySalary',
    description: 'Nuvarande lön (från {effectiveDate})',
    defaultMessage: 'Nuvarande lön (fr.o.m. {effectiveDate})',
  },
  latestUpdatedSalaryLabel: {
    id: 'workInformationMessages.latestUpdatedSalaryLabel',
    description: 'Latest salary update',
    defaultMessage: 'Senaste löneändring',
  },
  latestUpdatedSalaryDescription: {
    id: 'workInformationMessages.latestUpdatedSalaryDescription',
    description: '{salary} starts from {effectiveMonth}',
    defaultMessage: '{salary} från och med {effectiveMonth}',
  },
  currentMonthlyPensionPremium: {
    id: 'workInformationMessages.currentMonthlyPensionPremium',
    description: 'Current pension premium (from {effectiveDate})',
    defaultMessage: 'Nuvarande pensionspremie (fr.o.m. {effectiveDate})',
  },
  newMonthlyPensionPremium: {
    id: 'workInformationMessages.newMonthlyPensionPremium',
    description: 'New pension premium',
    defaultMessage: 'Ange ny pensionspremie',
  },
  currentStatus: {
    id: 'workInformationMessages.currentStatus',
    description: 'Nuvarande status',
    defaultMessage: 'Nuvarande status',
  },
  newStatus: {
    id: 'workInformationMessages.newStatus',
    description: 'Ny status',
    defaultMessage: 'Välj ny status',
  },
  personalDetails: {
    id: 'workInformationMessages.personalDetails',
    description: 'Personuppgifter',
    defaultMessage: 'Personuppgifter',
  },
  employmentDetails: {
    id: 'workInformationMessages.employmentDetails',
    description: 'Anställningsuppgifter',
    defaultMessage: 'Anställningsuppgifter',
  },
  superAdmin: {
    id: 'workInformationMessages.superAdmin',
    description: 'Super administrator',
    defaultMessage: 'Huvudadministratör',
  },
  HRAdmin: {
    id: 'workInformationMessages.HRAdmin',
    description: 'HR administrator',
    defaultMessage: 'HR-administratör',
  },
  HRAdminDescription: {
    id: 'workInformationMessages.HRAdminDescription',
    description: 'HR administrator description',
    defaultMessage:
      'HR-administratörer kan hantera anställda och deras uppgifter (inklusive löne- och förmånsuppgifter)',
  },
  contentAdmin: {
    id: 'workInformationMessages.contentAdmin',
    description: 'Content Kommunikatör',
    defaultMessage: 'Kommunikatör',
  },
  contentAdminDescription: {
    id: 'workInformationMessages.contentAdminDescription',
    description: 'HR administrator description',
    defaultMessage:
      'Kommunikatörer kan hantera företagets egna förmåner och artiklar men kan inte se uppgifter om några anställda',
  },
  membershipRole: {
    id: 'workInformationMessages.membershipRole',
    description: 'membership role',
    defaultMessage: 'Systemrättigheter',
  },
  informationAboutYourPension: {
    id: 'workInformationMessages.informationAboutYourPension',
    description: 'Information about your pension',
    defaultMessage: 'Information om din pension',
  },
  emailInsuranceWarning: {
    id: 'workInformationMessages.emailInsuranceWarning',
    description:
      'Insurance warning message showing if membership is missing email',
    defaultMessage:
      'E-post måste fyllas i för att riskförsäkringar ska tecknas',
  },
  monthlySalaryInsuranceWarning: {
    id: 'workInformationMessages.monthlySalaryInsuranceWarning',
    description:
      'Insurance warning message showing if membership is does not have a monthy salary',
    defaultMessage: 'Lön måste fyllas i för att sjukförsäkring ska tecknas',
  },
  financeAdmin: {
    id: 'workInformationMessages.financeAdmin',
    defaultMessage: 'Ekonomiadministratör',
  },
  financeAdminDescr: {
    id: 'workInformationMessages.financeAdminDescr',
    defaultMessage:
      'Ekonomiadministratörer kan hantera fakturor, bokföringsfiler och finansiell information men har inte tillgång till uppgifter om anställdas löner eller förmåner',
  },
  salaryAndOtherBenefits: {
    id: 'workInformationMessages.salaryAndOtherBenefits',
    description: 'Salary and other benefits',
    defaultMessage: 'Lön och andra ersättningar',
  },
  salaryAndOtherBenefitsDescription: {
    id: 'workInformationMessages.salaryAndOtherBenefitsDescription',
    defaultMessage:
      'Lön och andra ersättningar är de ersättningar som anställda får från sin arbetsgivare. Olika löner och ersättningar kan ligga till grund för olika förmåner som ålderspension och sjukförsäkring. Lön och andra ersättningar anges månadsvis. För att ändra vilka lönearter som är konfigurerade för ert företag, gå till <accountPlanHref>Kontoplan under Företagsinställningar</accountPlanHref>.',
  },
  noAdministrators: {
    id: 'workInformationMessages.noAdministrators',
    defaultMessage: 'Inga administratörer',
  },
  superAdminDescription: {
    id: 'workInformationMessages.superAdminDescription',
    description: 'Super administrator description',
    defaultMessage:
      'Huvudadministratörer har fullständig tillgång till alla företagets uppgifter',
  },
  editRemunerationSuccess: {
    id: 'workInformationMessages.editRemunerationSuccess',
    defaultMessage: 'Ändringarna har sparats',
  },
  remunerationInsuranceWarning: {
    id: 'workInformationMessages.remunerationInsuranceWarning',
    defaultMessage:
      '{remunerationType} måste fyllas i för att sjukförsäkring ska tecknas',
  },
});
