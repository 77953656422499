import { AdvinansOccupationalPension } from '@frontend/benefit-types';
import {
  DescriptionList,
  DescriptionListEntry,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import {
  BenefitPackage,
  FlexEntitlementRules,
  ManagingCompany,
  MiscConfiguration,
  RemunerationType,
} from 'app/apollo/graphql/types';
import {
  commonBenefitMessages,
  pensionBenefitDeductionMessages,
  pensionBenefitMessages,
  pensionProviderMessages,
} from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { FormattedList, FormattedMessage, useIntl } from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';

import { InBenefitPackage } from '../../../components/BenefitsTable/InBenefitPackage';
import { PremiumMatrixTable } from '../../../form/components/PremiumMatrixTable';
import {
  benefitFormMessages,
  benefitPensionPremiumCalculationMethodMessages,
} from '../../../form/messages';
import {
  formatPremiumMatrixFormValues,
  getPremiumCalculationMethod,
} from '../../../form/utils';
import {
  PENSION_PREMIUM_CALCULATION_METHODS_NLP,
  PENSION_PREMIUM_CALCULATION_METHODS_NODE,
} from '../../../form/utils/constants';

interface Benefit {
  configuration: MiscConfiguration;
  entitlementRules: Pick<FlexEntitlementRules, 'benefitPackageIds'> | null;
  id: string;
  type: string;
}

interface Props {
  benefit: AdvinansOccupationalPension<Benefit>;
  benefitPackageOptions: Pick<BenefitPackage, 'id' | 'name'>[];
  managedBy: ManagingCompany;
  remunerationTypes: readonly RemunerationType[];
}

export const OccupationalPensionBenefit: React.FC<Props> = ({
  benefit,
  benefitPackageOptions,
  managedBy,
  remunerationTypes,
}) => {
  const { formatMessage } = useIntl();
  const { configuration, entitlementRules } = benefit;

  if (!configuration?.data || !entitlementRules) {
    return null;
  }

  const {
    retirementAge,
    premiumMatrix,
    providers,
    payrollElementCode,
    premiumDeductions,
    fixedPremium,
    premiumWaiver,
    isActiveOnParentalLeave,
    payrollElementMultiplier,
  } = configuration.data;

  const pensionPayrollElement = remunerationTypes.find(
    type => type.id === payrollElementCode,
  );

  const isManagedByNode = managedBy === 'NODE';

  const PREMIUM_CALCULATION_METHOD_OPTIONS = isManagedByNode
    ? PENSION_PREMIUM_CALCULATION_METHODS_NODE
    : PENSION_PREMIUM_CALCULATION_METHODS_NLP;

  const premiumCalculationMethod = getPremiumCalculationMethod({
    availableOptions: PREMIUM_CALCULATION_METHOD_OPTIONS,
    premiumMatrix,
  });

  const entries: DescriptionListEntry[] = [
    {
      id: 'benefit.retirementAge',
      label: <FormattedMessage {...benefitFormMessages.retirementAge} />,
      value: retirementAge ? (
        <FormattedMessage
          {...commonMessages.nYears}
          values={{ count: retirementAge }}
        />
      ) : (
        <NoValue />
      ),
    },
    ...(isManagedByNode
      ? [
          {
            id: 'benefit.insuranceProviders',
            label: (
              <FormattedMessage {...commonBenefitMessages.insuranceProvider} />
            ),
            value: (
              <FormattedList
                type="unit"
                value={providers.map(provider => (
                  <FormattedMessage
                    messages={pensionProviderMessages}
                    select={provider}
                  />
                ))}
              />
            ),
          },
        ]
      : []),
    {
      id: 'benefit.premiumCalculationMethod',
      label: (
        <FormattedMessage {...benefitFormMessages.premiumCalculationMethod} />
      ),
      value: premiumCalculationMethod ? (
        <FormattedMessage
          select={premiumCalculationMethod}
          messages={benefitPensionPremiumCalculationMethodMessages}
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.payrollElement',
      label: (
        <FormattedMessage {...commonBenefitMessages.benefitQualifyingIncome} />
      ),
      value: pensionPayrollElement ? (
        <>
          {pensionPayrollElement.id} {pensionPayrollElement.name ?? ''}
        </>
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.annualSalaryFactor',
      label: <FormattedMessage {...commonBenefitMessages.annualFactor} />,
      value: payrollElementMultiplier ?? <NoValue />,
    },
    ...(premiumMatrix
      ? [
          {
            id: 'benefit.fixedPremium',
            label: (
              <FormattedMessage {...pensionBenefitMessages.fixedPremium} />
            ),
            value:
              fixedPremium != null ? (
                fixedPremium ? (
                  <FormattedMessage {...commonMessages.yes} />
                ) : (
                  <FormattedMessage {...commonMessages.no} />
                )
              ) : (
                <NoValue />
              ),
          },
        ]
      : []),
    {
      id: 'benefit.premiumWaiver',
      label: <FormattedMessage {...pensionBenefitMessages.premiumWaiver} />,
      value:
        premiumWaiver != null ? (
          premiumWaiver ? (
            <FormattedMessage {...commonMessages.yes} />
          ) : (
            <FormattedMessage {...commonMessages.no} />
          )
        ) : (
          <NoValue />
        ),
    },
    {
      id: 'benefit.premiumDeductions',
      label: <FormattedMessage {...pensionBenefitMessages.premiumDeductions} />,
      value: premiumDeductions.length ? (
        <FormattedList
          type="unit"
          value={premiumDeductions.map(deduction =>
            formatMessage({
              messages: pensionBenefitDeductionMessages,
              select: deduction,
            }),
          )}
        />
      ) : (
        <FormattedMessage {...commonMessages.no} />
      ),
    },
    ...(isManagedByNode
      ? [
          {
            id: 'benefit.parentalLeave',
            label: (
              <FormattedMessage {...commonBenefitMessages.parentalLeave} />
            ),
            value:
              isActiveOnParentalLeave != null ? (
                isActiveOnParentalLeave ? (
                  <FormattedMessage {...commonMessages.yes} />
                ) : (
                  <FormattedMessage {...commonMessages.no} />
                )
              ) : (
                <NoValue />
              ),
          },
        ]
      : []),
    {
      id: 'benefit.inBenefitPackage',
      label: <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />,
      value: (
        <InBenefitPackage
          benefit={benefit}
          benefitPackageOptions={benefitPackageOptions}
        />
      ),
    },
  ];

  return (
    <>
      <Subsection>
        <DescriptionList alignLeft entries={entries} />
      </Subsection>
      {premiumMatrix && (
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage
              {...benefitFormMessages.premiumCalculationMethod}
            />
          </SubsectionHeader>
          <PremiumMatrixTable
            matrix={formatPremiumMatrixFormValues(premiumMatrix)}
          />
        </Subsection>
      )}
    </>
  );
};
