import { EuroAccidentTgl } from '@frontend/benefit-types';
import { DescriptionList, DescriptionListEntry } from '@frontend/ui';
import {
  BenefitPackage,
  FlexEntitlementRules,
  ManagingCompany,
  MiscConfiguration,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';

import { InBenefitPackage } from '../../../components/BenefitsTable/InBenefitPackage';

interface Benefit {
  configuration: MiscConfiguration;
  entitlementRules: Pick<FlexEntitlementRules, 'benefitPackageIds'> | null;
  id: string;
  type: string;
}

interface Props {
  benefit: EuroAccidentTgl<Benefit>;
  benefitPackageOptions: Pick<BenefitPackage, 'id' | 'name'>[];
  managedBy: ManagingCompany;
}

export const TglBenefit: React.FC<Props> = ({
  benefit,
  benefitPackageOptions,
  managedBy,
}) => {
  const { configuration } = benefit;

  if (!configuration?.data) {
    return null;
  }

  const { isActiveOnParentalLeave } = configuration.data;

  const isManagedByNode = managedBy === 'NODE';

  const entries: DescriptionListEntry[] = [
    ...(isManagedByNode
      ? [
          {
            id: 'benefit.parentalLeave',
            label: (
              <FormattedMessage {...commonBenefitMessages.parentalLeave} />
            ),
            value:
              isActiveOnParentalLeave != null ? (
                isActiveOnParentalLeave ? (
                  <FormattedMessage {...commonMessages.yes} />
                ) : (
                  <FormattedMessage {...commonMessages.no} />
                )
              ) : (
                <NoValue />
              ),
          },
        ]
      : []),
    {
      id: 'benefit.inBenefitPackage',
      label: <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />,
      value: (
        <InBenefitPackage
          benefit={benefit}
          benefitPackageOptions={benefitPackageOptions}
        />
      ),
    },
  ];

  return <DescriptionList alignLeft entries={entries} />;
};
