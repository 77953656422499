import { formatIdentityNumber } from '@advinans/belt-se-identity-number';
import {
  Form,
  Formik,
  RadioGroupField,
  SelectField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid as UiGrid,
  ReadOnlyField,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import {
  companyDetailsQuery,
  companyDetailsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { Image, ImageUploadField } from 'components/formik/ImageUploadField';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50, TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import {
  ClearingFieldType,
  SelectClearingField,
} from 'features/companies/company/select-clearing';
import { COMPANY_DETAILS_QUERY } from 'features/companies/graphql/queries';
import {
  companyLegalEntityTypeMessages,
  companyMessages,
} from 'features/companies/messages';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { getSelectComponentMonthOptions } from 'utils/get-select-month-options';
import {
  isEmail,
  isValidEmailDomain,
  isValidPhoneNumber,
  VALID_EMAIL_DOMAINS,
} from 'validations';
import * as Yup from 'yup';

import { useSubmit } from './use-submit';

const Grid = styled(UiGrid)`
  margin-bottom: 2.5rem;
`;

export interface FormValues {
  accountClosureMonth: number | '';
  clearingCode: ClearingFieldType;
  displayName: string;
  email: string | null;
  hasCompanyGroup: string | null;
  logo: Image | null;
  phone: string | null;
  salaryReviewMonth: number | '';
  salesRep: string | null;
  submissionError?: string;
}

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    displayName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    salesRep: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    phone: Yup.string().test(
      'valid phone number',
      intl.formatMessage(validationMessages.invalidPhoneNumber),
      value => !value || isValidPhoneNumber(value),
    ),
    email: Yup.string()
      .test(
        'valid email',
        intl.formatMessage(validationMessages.isValidEmail),
        value => !value || isEmail(value),
      )
      .test(
        'valid email domain',
        intl.formatMessage(validationMessages.invalidEmailDomain, {
          domains: VALID_EMAIL_DOMAINS.join(', '),
        }),
        value => !value || isValidEmailDomain(value),
      ),
  });

const ImageUploadFieldLabel: React.FC = () => (
  <FormattedMessage {...companyMessages.editLogo} />
);

export const EditCompanyDetails: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = props => {
  const {
    match: {
      params: { companyId },
    },
  } = props;
  const intl = useIntl();
  const { formatMessage } = intl;

  const { submit, error: submissionError } = useSubmit(props);

  const { data, loading, error } = useQuery<
    companyDetailsQuery,
    companyDetailsQueryVariables
  >(COMPANY_DETAILS_QUERY, {
    variables: { id: companyId },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <EmptyStatePage error={error} parentLink="/" />;
  }

  const { company, nlpSalesReps } = data;

  const monthOptions = getSelectComponentMonthOptions({ intl });

  const salesRepOptions = nlpSalesReps.map(
    ({ id, firstName, lastName, externalId }) => ({
      label: `${firstName} ${lastName} (${externalId})`,
      value: id,
    }),
  );

  const initialValues: FormValues = {
    accountClosureMonth: company.accountClosureMonth ?? '',
    clearingCode: company.clearingCode
      ? {
          value: company.clearingCode.code,
          label: company.clearingCode.displayName,
        }
      : null,
    displayName: company.displayName,
    email: company.email,
    hasCompanyGroup: company.hasCompanyGroup ? 'true' : 'false',
    phone: company.phone,
    salaryReviewMonth: company.salaryReviewMonth ?? '',
    salesRep: company.nlpSalesRep?.id ?? '',
    logo: company.logoUrl ? { previewUrl: company.logoUrl } : null,
  };
  const registrationNumber = formatIdentityNumber(
    data.company.registrationNumber,
  );

  return (
    <Formik<FormValues>
      validationSchema={validationSchema(intl)}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <TextGrid>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...companyMessages.about} />
              </SubsectionHeader>
              {error && <GraphQlError error={error} />}
              <Grid>
                <GridCell50>
                  <ReadOnlyField
                    label={
                      <FormattedMessage
                        {...commonMessages.registrationNumber}
                      />
                    }
                    value={registrationNumber}
                  />
                </GridCell50>
                <GridCell50>
                  <ReadOnlyField
                    label={
                      <FormattedMessage {...companyMessages.companyAddress} />
                    }
                    value={company.address.line1}
                  />
                </GridCell50>
                <GridCell50>
                  <ReadOnlyField
                    label={
                      <FormattedMessage {...companyMessages.companyName} />
                    }
                    value={company.name}
                  />
                </GridCell50>
                <GridCell50>
                  <ReadOnlyField
                    label={<FormattedMessage {...commonMessages.postalCode} />}
                    value={company.address.postalCode}
                  />
                </GridCell50>
                <GridCell50>
                  <ReadOnlyField
                    label={
                      <FormattedMessage {...companyMessages.legalEntityType} />
                    }
                    value={
                      <FormattedMessage
                        select={company.legalEntityType}
                        messages={companyLegalEntityTypeMessages}
                      />
                    }
                  />
                </GridCell50>
                <GridCell50>
                  <ReadOnlyField
                    label={<FormattedMessage {...commonMessages.city} />}
                    value={company.address.city}
                  />
                </GridCell50>
              </Grid>
              <TextField
                dense
                name="displayName"
                label={formatMessage(companyMessages.displayName)}
                required
              />
              <SelectField
                name="salesRep"
                dense
                required
                options={salesRepOptions}
                label={formatMessage(companyMessages.salesRep)}
              />
              <ImageUploadField
                name="logo"
                imageType="logotype"
                label={ImageUploadFieldLabel}
                companyId={companyId}
              />
              <Grid>
                <GridCell50>
                  <RadioGroupField
                    name="hasCompanyGroup"
                    label={
                      <FormattedMessage {...companyMessages.hasCompanyGroup} />
                    }
                    options={[
                      {
                        label: formatMessage(commonMessages.yes),
                        value: 'true',
                      },
                      {
                        label: formatMessage(commonMessages.no),
                        value: 'false',
                      },
                    ]}
                  />
                </GridCell50>
              </Grid>
            </Subsection>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...companyMessages.contact} />
              </SubsectionHeader>
              <TextField
                dense
                name="email"
                type="email"
                label={formatMessage(commonMessages.email)}
              />
              <TextField
                dense
                name="phone"
                type="tel"
                label={formatMessage(commonMessages.phone)}
              />
            </Subsection>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...companyMessages.other} />
              </SubsectionHeader>
              <SelectClearingField name="clearingCode" />
              <SelectField
                name="accountClosureMonth"
                dense
                options={monthOptions}
                label={formatMessage(companyMessages.accountClosureMonth)}
              />
              <SelectField
                name="salaryReviewMonth"
                dense
                options={monthOptions}
                label={formatMessage(companyMessages.salaryReviewMonth)}
              />
            </Subsection>
          </TextGrid>
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              filled
              disabled={!dirty || !isValid}
              type="submit"
              loading={isSubmitting}
            >
              <FormattedMessage {...formMessages.save} />
            </Button>
          </ButtonLayout>
        </Form>
      )}
    </Formik>
  );
};
