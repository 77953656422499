import { A, Table, Td, TdChip, Th, Tr } from '@frontend/ui';
import { select } from '@frontend/utils';
import { invoiceDetails } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { report } from 'components/ErrorBoundary/lib/report';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';

import { INVOICE_SUPPLIERS } from '../..';
import { invoiceMessages } from '../../messages';
import { InvoiceDownloadMenu } from '../InvoiceDownloadMenu';

interface TableProps {
  invoices: invoiceDetails[];
  navigation?: React.ReactNode;
}

interface RowProps {
  hasShredded: boolean;
  invoice: invoiceDetails;
}

const InvoiceTableRow: React.FC<RowProps> = ({ invoice, hasShredded }) => {
  const supplier =
    select({
      params: {
        key: invoice.supplierType,
        record: INVOICE_SUPPLIERS,
        user: 'backstage',
      },
      report,
      shouldReportError: window.env.ERROR_REPORTING_ACTIVE === 'true',
    }) ?? invoice.supplierType;
  return (
    <Tr
      inlineMenu={
        <InvoiceDownloadMenu
          sie4Url={invoice.sie4Url}
          peppolUrl={invoice.peppolUrl}
          pdfUrl={invoice.pdfUrl}
          excelUrl={invoice.excelUrl}
          csvUrl={invoice.csvUrl}
        />
      }
    >
      <Td>
        {invoice.htmlUrl ? (
          <A href={invoice.htmlUrl} target="_blank">
            <span title={supplier}>{supplier}</span>
          </A>
        ) : invoice.pdfUrl ? (
          <A href={invoice.pdfUrl} target="_blank">
            <span title={supplier}>{supplier}</span>
          </A>
        ) : (
          <span title={supplier}>{supplier}</span>
        )}
      </Td>
      {hasShredded && (
        <Td>
          {invoice.shredded && (
            <TdChip
              text={<FormattedMessage {...invoiceMessages.shredded} />}
              colorScheme="greyed"
            />
          )}
        </Td>
      )}
      <Td type="number" align="left">
        {invoice.invoiceNumber || <NoValue />}
      </Td>
      <Td type="number">
        {invoice.issueDate ? (
          <FormattedDate value={invoice.issueDate} />
        ) : (
          <NoValue />
        )}
      </Td>
      <Td type="number">
        {invoice.dueDate ? (
          <FormattedDate value={invoice.dueDate} />
        ) : (
          <NoValue />
        )}
      </Td>
      <Td type="number">
        {invoice.payableAmount ? (
          <FormattedCurrency
            value={invoice.payableAmount}
            noSuffix
            currency="SEK"
          />
        ) : (
          <NoValue />
        )}
      </Td>
    </Tr>
  );
};

export const InvoicesTable: React.FC<TableProps> = ({
  invoices,
  navigation,
}) => {
  const hasShredded = invoices.some(invoice => invoice.shredded);
  return (
    <Table navigation={navigation}>
      <colgroup>
        <col style={{ width: 'auto' }} />
        <col style={{ width: '8rem' }} />
        <col style={{ width: '8rem' }} />
        <col style={{ width: '8rem' }} />
        <col style={{ width: '8rem' }} />
        <col style={{ width: '6rem' }} />
      </colgroup>
      <thead>
        <Tr withInlineMenuTh>
          <Th>
            <FormattedMessage {...invoiceMessages.supplier} />
          </Th>
          {hasShredded && <Th />}
          <Th>
            <FormattedMessage {...invoiceMessages.invoiceNumber} />
          </Th>
          <Th type="number">
            <FormattedMessage {...invoiceMessages.invoiceDate} />
          </Th>
          <Th type="number">
            <FormattedMessage {...invoiceMessages.dueDate} />
          </Th>
          <Th type="number">
            <FormattedMessage {...commonMessages.amountLabel} />
          </Th>
        </Tr>
      </thead>
      <tbody>
        {!invoices.length ? (
          <Tr>
            <Td colSpan={6}>
              <FormattedMessage {...invoiceMessages.noInvoiceMatch} />
            </Td>
          </Tr>
        ) : (
          invoices.map(invoice => (
            <InvoiceTableRow
              key={invoice.id}
              invoice={invoice}
              hasShredded={hasShredded}
            />
          ))
        )}
      </tbody>
    </Table>
  );
};
