import { ContentContainer, PageLede, Section } from '@frontend/ui';
import { isNotNullOrUndefined, toSafeArray } from '@frontend/utils';
import { EmptyState } from 'components/EmptyState';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import { RejectSalaryExchangeRequests } from 'features/companies/company/salary-exchanges/requests/reject';
import { RejectBonusSalaryExchangeRequests } from 'features/companies/company/salary-exchanges/requests/reject/bonus';
import { RejectForm } from 'features/companies/company/salary-exchanges/requests/reject/components/Form';
import { Page } from 'features/page';
import { Suspense } from 'features/Suspense';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';

import { SalaryExchangeRouteMatchParams } from '../..';
import { CollectumInfo } from '../CollectumInfo';

export const Reject: React.FC<RouteComponentProps> = props => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();
  const { formatMessage } = useIntl();
  const { location } = props;
  const search = qs.parse(location.search);

  const requestIds: Array<string | null> = toSafeArray(search.requestIds);

  const approved = search.approved === 'true';

  const _requestIds = requestIds.filter(isNotNullOrUndefined);

  if (!_requestIds.length) {
    return <EmptyStatePage parentLink={`/companies/${companyId}`} />;
  }

  const title = formatMessage(
    approved
      ? companySalaryExchangeMessages.rejectUpcoming
      : companySalaryExchangeMessages.reject,
  );

  return (
    <NavigationAnchor
      title={title}
      url={`${location.pathname}${location.search}`}
    >
      <Page
        parentLink={
          approved
            ? `/companies/${companyId}/salary-exchanges`
            : `/companies/${companyId}/salary-exchanges/requests`
        }
        title={title}
      >
        <Suspense fallback={<TopLoading />} batch emptyState={<EmptyState />}>
          {({ isEmpty, errors }) => (
            <ContentContainer>
              <Section>
                {errors && <GraphQlErrors errors={errors} />}
                {!isEmpty() && (
                  <PageLede>
                    <FormattedMessage
                      {...companySalaryExchangeMessages.rejectAdvinansDesc}
                    />
                    <CollectumInfo />
                  </PageLede>
                )}
                <RejectForm
                  omitForm={isEmpty()}
                  approved={approved}
                  requestIds={_requestIds}
                  {...props}
                >
                  <RejectSalaryExchangeRequests
                    approved={approved}
                    requestIds={_requestIds}
                  />
                  <RejectBonusSalaryExchangeRequests requestIds={_requestIds} />
                </RejectForm>
              </Section>
            </ContentContainer>
          )}
        </Suspense>
      </Page>
    </NavigationAnchor>
  );
};
