import {
  isAccidentInsuranceBenefit,
  isDigitalAdviceBenefit,
  isDisabilityInsuranceBenefit,
  isEpassiBenefit,
  isEpassiBikeBenefit,
  isHealthInsuranceBenefit,
  isLifeInsuranceBenefit,
  isOccupationalPensionBenefit,
  isPersonalAdviceBenefit,
  isSalaryExchangeBenefit,
  isSicknessAccidentInsuranceBenefit,
  isSynsamBenefit,
  isTglInsuranceBenefit,
  isWellnessBenefit,
} from '@frontend/benefit-types';
import { ContentContainer, Section } from '@frontend/ui';
import {
  versionHistoryQuery,
  versionHistoryQueryVariables,
} from 'app/apollo/graphql/types';
import { benefitTypesMessages } from 'app/messages/benefits';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '..';
import { AccidentBenefit } from './accident';
import { DigitalAdviceBenefit } from './digital-advice';
import { EpassiBenefit } from './epassi';
import { EpassiBikeBenefit } from './epassi-bike';
import { VERSION_HISTORY_QUERY } from './graphql/queries';
import { HealthBenefit } from './health';
import { LifeBenefit } from './life';
import { OccupationalPensionBenefit } from './pension';
import { PersonalAdviceBenefit } from './personal-advice';
import { SalaryExchangeBenefit } from './salary-exchange';
import { SicknessBenefit } from './sickness';
import { SicknessAndAccidentBenefit } from './sickness-and-accident';
import { SynsamBenefit } from './synsam';
import { TglBenefit } from './tgl';
import { WellnessBenefit } from './wellness';

export const Version: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { formatMessage } = useIntl();
  const {
    params: { asOf, benefitId, companyId },
  } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    versionHistoryQuery,
    versionHistoryQueryVariables
  >(VERSION_HISTORY_QUERY, {
    errorPolicy: 'all',
    variables: {
      companyId,
      benefitId,
      asOf,
    },
  });

  if (loading) {
    return <TopLoading />;
  }

  const benefit = data?.flexBenefit;
  const company = data?.company;

  const parentLink = `/companies/${params.companyId}/benefit-packages/benefits/${params.benefitId}/version-history`;

  if (!benefit || !company?.benefitPackages) {
    return <EmptyStatePage error={error} parentLink={parentLink} />;
  }

  const benefitPackageOptions = company.benefitPackages.edges.map(
    ({ node }) => node,
  );

  const remunerationTypes =
    company.remunerationTypes?.edges.map(({ node }) => node) ?? [];

  const { managedBy } = company;

  const renderBenefitComponent = () => {
    switch (true) {
      case isAccidentInsuranceBenefit(benefit):
        return (
          <AccidentBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
            managedBy={managedBy}
          />
        );
      case isDigitalAdviceBenefit(benefit):
        return (
          <DigitalAdviceBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
          />
        );
      case isDisabilityInsuranceBenefit(benefit):
        return (
          <SicknessBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
            managedBy={managedBy}
            remunerationTypes={remunerationTypes}
          />
        );
      // case isEmployersPensionBenefit(benefit):
      //  return <EditEmployersPension />;
      case isEpassiBenefit(benefit):
        return (
          <EpassiBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
          />
        );
      case isEpassiBikeBenefit(benefit):
        return (
          <EpassiBikeBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
          />
        );
      case isHealthInsuranceBenefit(benefit):
        return (
          <HealthBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
            managedBy={managedBy}
          />
        );
      case isLifeInsuranceBenefit(benefit):
        return (
          <LifeBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
            managedBy={managedBy}
          />
        );
      case isOccupationalPensionBenefit(benefit):
        return (
          <OccupationalPensionBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
            managedBy={managedBy}
            remunerationTypes={remunerationTypes}
          />
        );
      case isPersonalAdviceBenefit(benefit):
        return (
          <PersonalAdviceBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
          />
        );
      case isSalaryExchangeBenefit(benefit):
        return (
          <SalaryExchangeBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
            managedBy={managedBy}
            remunerationTypes={remunerationTypes}
          />
        );
      case isSicknessAccidentInsuranceBenefit(benefit):
        return (
          <SicknessAndAccidentBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
            managedBy={managedBy}
          />
        );
      case isSynsamBenefit(benefit):
        return (
          <SynsamBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
          />
        );
      case isTglInsuranceBenefit(benefit):
        return (
          <TglBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
            managedBy={managedBy}
          />
        );
      case isWellnessBenefit(benefit):
        return (
          <WellnessBenefit
            benefit={benefit}
            benefitPackageOptions={benefitPackageOptions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <NavigationAnchor
      title={formatMessage({
        select: benefit.type,
        messages: benefitTypesMessages,
      })}
      url={parentLink}
    >
      <Page
        title={
          <FormattedMessage
            select={benefit.type}
            messages={benefitTypesMessages}
          />
        }
        parentLink={parentLink}
      >
        <ContentContainer>
          <Section>{renderBenefitComponent()}</Section>
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
