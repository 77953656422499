import {
  isAccidentInsuranceBenefit,
  isDigitalAdviceBenefit,
  isDisabilityInsuranceBenefit,
  //   isEmployersPensionBenefit,
  isEpassiBenefit,
  isEpassiBikeBenefit,
  isHealthInsuranceBenefit,
  isLifeInsuranceBenefit,
  isOccupationalPensionBenefit,
  isPersonalAdviceBenefit,
  isSalaryExchangeBenefit,
  isSicknessAccidentInsuranceBenefit,
  isSynsamBenefit,
  isTglInsuranceBenefit,
  isWellnessBenefit,
} from '@frontend/benefit-types';
import { ContentContainer } from '@frontend/ui';
import {
  companyBenefitsEditLayoutQuery,
  companyBenefitsEditLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import {
  benefitTypesMessages,
  commonBenefitMessages,
} from 'app/messages/benefits';
import { COMPANY_BENEFITS_EDIT_LAYOUT_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { RoutedTab } from 'components/RoutedTabBar';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { EditAccident } from 'features/companies/company/benefits/edit/accident';
import { EditDigitalAdvice } from 'features/companies/company/benefits/edit/digital-advice';
import { EditDisability } from 'features/companies/company/benefits/edit/disability';
import { EditEpassi } from 'features/companies/company/benefits/edit/epassi';
import { EditEpassiBike } from 'features/companies/company/benefits/edit/epassi-bike';
// import { EditEmployersPension } from 'features/companies/company/benefits/edit/employers-pension';
import { EditHealthcare } from 'features/companies/company/benefits/edit/healthcare';
import { EditLife } from 'features/companies/company/benefits/edit/life';
import { EditOccupationalPension } from 'features/companies/company/benefits/edit/occupational-pension';
import { EditPersonalAdvice } from 'features/companies/company/benefits/edit/personal-advice';
import { EditSalaryExchange } from 'features/companies/company/benefits/edit/salary-exchange';
import { EditSicknessAndAccident } from 'features/companies/company/benefits/edit/sickness-and-accident';
import { EditSynsam } from 'features/companies/company/benefits/edit/synsam';
import { EditTgl } from 'features/companies/company/benefits/edit/tgl';
import { EditWellness } from 'features/companies/company/benefits/edit/wellness';
import { VersionHistory } from 'features/companies/company/benefits/version-history';
import { Version } from 'features/companies/company/benefits/version-history/version';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../..';

interface Tab extends RoutedTab {
  path: string;
}

export interface MatchParams extends CompanyMatchParams {
  benefitId: string;
}

export const BenefitsEditRoute: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { params, url } = match;
  const { formatMessage } = useIntl();
  const { data, loading, error } = useQuery<
    companyBenefitsEditLayoutQuery,
    companyBenefitsEditLayoutQueryVariables
  >(COMPANY_BENEFITS_EDIT_LAYOUT_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId },
  });

  if (loading) {
    return <TopLoading />;
  }

  const parentLink = `/companies/${params.companyId}/benefit-packages/benefits`;

  const benefit = data?.flexBenefit;

  if (!benefit?.type) {
    return <EmptyStatePage error={error} parentLink={parentLink} />;
  }

  const renderBenefitComponent = () => {
    switch (true) {
      case isAccidentInsuranceBenefit(benefit):
        return <EditAccident />;
      case isDigitalAdviceBenefit(benefit):
        return <EditDigitalAdvice />;
      case isDisabilityInsuranceBenefit(benefit):
        return <EditDisability />;
      // case isEmployersPensionBenefit(benefit):
      //   return <EditEmployersPension />;
      case isEpassiBenefit(benefit):
        return <EditEpassi />;
      case isEpassiBikeBenefit(benefit):
        return <EditEpassiBike />;
      case isHealthInsuranceBenefit(benefit):
        return <EditHealthcare />;
      case isLifeInsuranceBenefit(benefit):
        return <EditLife />;
      case isOccupationalPensionBenefit(benefit):
        return <EditOccupationalPension />;
      case isPersonalAdviceBenefit(benefit):
        return <EditPersonalAdvice />;
      case isSalaryExchangeBenefit(benefit):
        return <EditSalaryExchange />;
      case isSicknessAccidentInsuranceBenefit(benefit):
        return <EditSicknessAndAccident />;
      case isSynsamBenefit(benefit):
        return <EditSynsam />;
      case isTglInsuranceBenefit(benefit):
        return <EditTgl />;
      case isWellnessBenefit(benefit):
        return <EditWellness />;
      default:
        return null;
    }
  };

  const tabs: Tab[] = [
    {
      title: commonBenefitMessages.settings,
      url: match.url,
      path: match.path,
    },
    {
      title: commonBenefitMessages.versionHistory,
      url: `${match.url}/version-history`,
      path: `${match.path}/version-history`,
    },
  ];

  return (
    <NavigationAnchor
      title={formatMessage(commonBenefitMessages.editBenefit)}
      url={url}
    >
      <Switch>
        <Route exact path={tabs.map(({ path }) => path)}>
          <Page
            title={
              <FormattedMessage
                select={benefit.type}
                messages={benefitTypesMessages}
              />
            }
            parentLink={parentLink}
            tabs={tabs}
          >
            <ContentContainer>
              <Route exact path={match.path}>
                {renderBenefitComponent()}
              </Route>
              <Route
                exact
                path={`${match.path}/version-history`}
                component={VersionHistory}
              />
            </ContentContainer>
          </Page>
        </Route>
        <Route
          exact
          path={`${match.path}/version-history/:asOf`}
          component={Version}
        />
      </Switch>
    </NavigationAnchor>
  );
};
