import { Section, SectionHeader } from '@frontend/ui';
import { archive } from '@frontend/ui/icons';
import {
  smeCompanyProposalsQuery,
  smeCompanyProposalsQueryVariables,
  SortingDirection,
  SortingFields,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/sme/company';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { useTableSort } from 'app/utils/use-table-sort';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { useDelete } from './delete';
import { useDuplicateProposal } from './duplicate/utils/use-duplicate-proposal';
import { SME_COMPANY_PROPOSALS_QUERY } from './graphql/queries';
import { ProposalsTable } from './Table';
import { getSortingQueryArgs } from './utils/get-sorting-query-args';
import { updateProposalsQuery } from './utils/update-proposals-query';

export type SortableColumns = SortingFields.STATUS | SortingFields.UPDATED_AT;

export const ProposalsSection: React.FC = () => {
  const { params } = useRouteMatch<CompanyMatchParams>();
  const { _delete, deleteLoading, deleteError } = useDelete();
  const { duplicate } = useDuplicateProposal();
  const [perPage, setPerPage] = useState(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const { handleSort, order, orderBy } = useTableSort<SortableColumns>({
    order: SortingDirection.DESC,
    orderBy: SortingFields.UPDATED_AT,
  });
  const queryArgs = getSortingQueryArgs({ order, orderBy });

  const { data, previousData, loading, fetchMore } = useQuery<
    smeCompanyProposalsQuery,
    smeCompanyProposalsQueryVariables
  >(SME_COMPANY_PROPOSALS_QUERY, {
    errorPolicy: 'all',
    // This fetch policy is used to ensure that we yield the latest edited proposal in the
    // table without having to implement some esoteric cache evition or refetch logic
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      companyId: params.companyId,
      first: Number(perPage),
      orderBy: queryArgs,
    },
  });

  const _data = data ?? previousData;

  if (loading && !_data) {
    return <TopLoading />;
  }

  if (!_data?.proposals?.edges.length) {
    return (
      <EmptyState
        title={
          <FormattedMessage {...smeCompanyProposalsMessages.noProposalsTitle} />
        }
        icon={archive}
      >
        <FormattedMessage
          {...smeCompanyProposalsMessages.noProposalsDescription}
        />
      </EmptyState>
    );
  }

  const { edges, pageInfo } = _data.proposals;
  const proposals = edges.map(({ node }) => node);

  const navigation = (
    <TableNavigation
      perPage={perPage}
      setPerPage={setPerPage}
      onNextPage={() => {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
          updateQuery: updateProposalsQuery,
        });
      }}
      onPreviousPage={() => {
        fetchMore({
          variables: {
            before: pageInfo.startCursor,
            first: undefined,
            last: Number(perPage),
          },
          updateQuery: updateProposalsQuery,
        });
      }}
      pageInfo={pageInfo}
    />
  );

  const _loading = loading || deleteLoading;

  return (
    <>
      {_loading && <TopLoading />}
      <Section>
        <SectionHeader>
          <FormattedMessage {...smeCompanyProposalsMessages.proposals} />
        </SectionHeader>
        {deleteError && <GraphQlError error={deleteError} />}
        <ProposalsTable
          navigation={navigation}
          proposals={proposals}
          sort={{ order, orderBy, handleSort }}
          handleDeleteProposal={_delete}
          handleDuplicateProposal={duplicate}
        />
      </Section>
    </>
  );
};
