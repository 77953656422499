import { CompanySize, LegalEntityType } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const companyMessages = defineMessages({
  companyLanguage: {
    id: 'companies.companyLanguage',
    defaultMessage: 'Koncernspråk',
  },
  registrationNumber: {
    id: 'companies.registrationNumber',
    defaultMessage: 'Person-/Organisationsnummer',
  },
  startDate: {
    id: 'companies.startDate',
    defaultMessage: 'Startdatum/Första inloggningsdatum',
  },
  firstName: { id: 'companies.firstName', defaultMessage: 'Förnamn' },
  lastName: { id: 'companies.lastName', defaultMessage: 'Efternamn' },
  pid: { id: 'companies.pid', defaultMessage: 'Personnummer' },
  email: { id: 'companies.email', defaultMessage: 'E-post' },
  phone: { id: 'companies.phone', defaultMessage: 'Telefonnummer' },
  isOwner: {
    id: 'companies.isOwner',
    defaultMessage: 'Äger mer än x procent av bolaget',
  },
  nordeaAgreement: {
    id: 'companies.nordeaAgreement',
    defaultMessage: 'Avtal Nordea',
  },
  created: {
    id: 'companies.created',
    defaultMessage: 'Företag har skapats',
  },
  customerInformation: {
    id: 'companies.customerInformation',
    defaultMessage: 'Kunduppgifter',
  },
  addCompany: {
    id: 'companies.addCompany',
    defaultMessage: 'Lägg till företag',
  },
  nodeAdmin: {
    id: 'companies.nodeAdmin',
    defaultMessage: 'Administratör Nordea Node',
  },
  eaAgreement: {
    id: 'companies.eaAgreement',
    defaultMessage: 'Avtal EuroAccident',
  },
  createNordea: {
    id: 'companies.createNordea',
    defaultMessage: 'Skapa avtal Nordea',
  },
  nordeaSalesRep: {
    id: 'companies.nordeaSalesRep',
    defaultMessage: 'Ansvarig säljare (NID)',
  },
  nordeaFundFeeDiscountType: {
    id: 'companies.nordeaFundFeeDiscountType',
    defaultMessage: 'Fond rabattnivå',
  },
  nordeaStartDate: {
    id: 'companies.nordeaStartDate',
    defaultMessage: 'Startdatum avtal',
  },
  createEaGrupp: {
    id: 'companies.createEaGrupp',
    defaultMessage: 'Skapa EuroAccident gruppavtal',
  },
  createEaTjp: {
    id: 'companies.createEaTjp',
    defaultMessage: 'Skapa EuroAccident TJP avtal',
  },
  eaGruppAgreementType: {
    id: 'companies.eaGruppAgreementType',
    defaultMessage: 'Gruppavtal typ',
  },
  companySize: {
    id: 'companies.companySize',
    defaultMessage: 'Storlek bolag',
  },
  eaExtendedFcfw: {
    id: 'companies.eaExtendedFcfw',
    defaultMessage: 'Utökad fullt arbetsför',
  },
  eaStartDate: {
    id: 'companies.eaStartDate',
    defaultMessage: 'Startdatum avtal',
  },
  discountValue: {
    id: 'companies.discountValue',
    defaultMessage: 'Rabatt värde',
  },
  discountPercent: {
    id: 'companies.discountPercent',
    defaultMessage: 'Rabatt procent',
  },
  discountType: {
    id: 'companies.discountType',
    defaultMessage: 'Administrativ rabattyp',
  },
  administrator: {
    id: 'companies.administrator',
    defaultMessage: 'Huvudadministratör',
  },
  addAdministrativeDiscount: {
    id: 'companies.addAdministrativeDiscount',
    defaultMessage: 'Lägg till administrativ rabatt',
  },
  nordeaCapitalFeeFund: {
    id: 'companies.nordeaCapitalFeeFund',
    defaultMessage: 'Fond: Rabatt på kapitalavgift',
  },
  nordeaAnnualFeeFund: {
    id: 'companies.nordeaAnnualFeeFund',
    defaultMessage: 'Fond: Rabatt på årsavgift',
  },
  nordeaCapitalFeeGuarantee: {
    id: 'companies.nordeaCapitalFeeGuarantee',
    defaultMessage: 'Garanti: Rabatt på kapitalavgift',
  },
  nordeaAnnualFeeGuarantee: {
    id: 'companies.nordeaAnnualFeeGuarantee',
    defaultMessage: 'Garanti: Rabatt på årsavgift',
  },
  addAgreement: {
    id: 'companies.addAgreement',
    defaultMessage: 'Lägg till avtal',
  },
  clearingSalesOffice: {
    id: 'companies.clearingSalesOffice',
    defaultMessage: 'Clearing ansvarigt kontor',
  },
  accountClosureMonth: {
    id: 'companies.accountClosureMonth',
    defaultMessage: 'Bokslutsmånad',
  },
  displayName: {
    id: 'companies.displayName',
    defaultMessage: 'Synligt namn',
  },
  companyAddress: {
    id: 'companies.companyAddress',
    defaultMessage: 'Företagsadress',
  },
  companyName: {
    id: 'companies.companyName',
    defaultMessage: 'Företagets namn',
  },
  salaryReviewMonth: {
    id: 'companies.salaryReviewMonth',
    defaultMessage: 'Lönerevisionsmånad',
  },
  legalEntityType: {
    id: 'companies.legalEntityType',
    defaultMessage: 'Bolagsform',
  },
  hasCompanyGroup: {
    id: 'companies.hasCompanyGroup',
    defaultMessage: 'Del i koncern',
  },
  kyc: {
    id: 'companies.kyc',
    defaultMessage: 'Känn din kund (KDK)',
  },
  salesRep: {
    id: 'companies.salesRep',
    defaultMessage: 'Ansvarig säljare',
  },
  about: {
    id: 'companies.about',
    defaultMessage: 'Uppgifter om organisationen',
  },
  contact: {
    id: 'companies.contact',
    defaultMessage: 'Kontaktuppgifter',
  },
  other: {
    id: 'companies.other',
    defaultMessage: 'Övriga uppgifter',
  },
  editLogo: {
    id: 'companies.editLogo',
    defaultMessage: 'Ändra logotyp',
  },
});

export const companySizeMessages = defineMessages<CompanySize>({
  LT_10: {
    id: 'companySizeMessages.LT_10',
    defaultMessage: 'XS (0-9)',
  },
  LT_50: {
    id: 'companySizeMessages.LT_50',
    defaultMessage: 'S (0-49)',
  },
  GTE_50: {
    id: 'companySizeMessages.GTE_50',
    defaultMessage: 'M (50-249)',
  },
  GTE_250: {
    id: 'companySizeMessages.GTE_250',
    defaultMessage: 'L (250-999)',
  },
  GTE_1000: {
    id: 'companySizeMessages.GTE_1000',
    defaultMessage: 'XL (1000+)',
  },
});

export const companyLegalEntityTypeMessages = defineMessages<LegalEntityType>({
  BRANCH: {
    id: 'smeCompanyLegalEntityTypeMessages.BRANCH',
    defaultMessage: 'Filial',
  },
  ECONOMIC_ASSOCIATION: {
    id: 'smeCompanyLegalEntityTypeMessages.ECONOMIC_ASSOCIATION',
    defaultMessage: 'Ekonomisk förening',
  },
  FOUNDATION: {
    id: 'smeCompanyLegalEntityTypeMessages.FOUNDATION',
    defaultMessage: 'Stiftelse',
  },
  LIMITED_COMPANY: {
    id: 'smeCompanyLegalEntityTypeMessages.LIMITED_COMPANY',
    defaultMessage: 'Aktiebolag',
  },
  LIMITED_PARTNERSHIP: {
    id: 'smeCompanyLegalEntityTypeMessages.LIMITED_PARTNERSHIP',
    defaultMessage: 'Kommanditbolag',
  },
  NONPROFIT_ASSOCIATION: {
    id: 'smeCompanyLegalEntityTypeMessages.NONPROFIT_ASSOCIATION',
    defaultMessage: 'Ideell förening',
  },
  SOLE_TRADER: {
    id: 'smeCompanyLegalEntityTypeMessages.SOLE_TRADER',
    defaultMessage: 'Enskild näringsidkare',
  },
  TRADING_PARTNERSHIP: {
    id: 'smeCompanyLegalEntityTypeMessages.TRADING_PARTNERSHIP',
    defaultMessage: 'Handelsbolag',
  },
});
