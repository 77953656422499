import {
  employeesWithPersonalAdviceQuery,
  employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice as EmployeeWithPersonalAdvice,
  employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership as Membership,
  employeesWithPersonalAdviceQueryVariables,
  MembershipKey,
} from 'app/apollo/graphql/types';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';

import { EMPLOYEES_WITH_PERSONAL_ADVICE_QUERY } from '../graphql/queries';
import { FilterParams } from '../utils/use-filter-params';
import { updatePersonalAdviceEmployeesQuery } from './lib/update-personal-advice-employees-query';
import { PersonalAdviceEmployeesTable } from './table';

export type PersonalAdviceEmployee = EmployeeWithPersonalAdvice & {
  membership: Membership;
};

interface Props {
  clearFilter: () => void;
  filterParams: FilterParams;
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  openSendInvitationsModal: (membershipKeys: Array<MembershipKey>) => void;
  toggleFilterSideSheet: () => void;
}

export const PersonalAdviceEmployees: React.FC<Props> = ({
  openAddHeldMeetingModal,
  openSendInvitationsModal,
  toggleFilterSideSheet,
  clearFilter,
  filterParams,
}) => {
  const location = useLocation();

  const { search, per_page } = qs.parse(location.search);

  const { data, loading, previousData, error, fetchMore } = useQuery<
    employeesWithPersonalAdviceQuery,
    employeesWithPersonalAdviceQueryVariables
  >(EMPLOYEES_WITH_PERSONAL_ADVICE_QUERY, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE_SMALL,
      search,
      companyId: filterParams.companyId || undefined,
      benefitPackageIds:
        filterParams.benefitPackageIds.length > 0
          ? filterParams.benefitPackageIds
          : undefined,
      minSalary: filterParams.minSalary
        ? Number(filterParams.minSalary)
        : undefined,
      maxSalary: filterParams.maxSalary
        ? Number(filterParams.maxSalary)
        : undefined,
      minBirthdate: filterParams.minBirthdate || undefined,
      maxBirthdate: filterParams.maxBirthdate || undefined,
      today: new Date().toISOString().substring(0, 10),
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend: false,
  });

  const _data = data ?? previousData;
  const employees =
    _data?.employeesWithPersonalAdvice?.edges
      .map(e => e.node)
      .filter(
        (node): node is PersonalAdviceEmployee => node.membership != null,
      ) ?? [];
  const pageInfo = _data?.employeesWithPersonalAdvice.pageInfo ?? {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  };

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <PersonalAdviceEmployeesTable
        pageInfo={pageInfo}
        onNextPage={() => {
          fetchMore({
            variables: {
              after: pageInfo.endCursor,
            },
            updateQuery: updatePersonalAdviceEmployeesQuery,
          });
        }}
        onPreviousPage={() => {
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
              first: undefined,
              last: per_page
                ? parseInt(per_page, 10)
                : DEFAULT_RESULT_PER_PAGE_SMALL,
            },
            updateQuery: updatePersonalAdviceEmployeesQuery,
          });
        }}
        employees={employees}
        openAddHeldMeetingModal={openAddHeldMeetingModal}
        toggleFilterSideSheet={toggleFilterSideSheet}
        clearFilter={clearFilter}
        openSendInvitationsModal={openSendInvitationsModal}
      />
    </>
  );
};
