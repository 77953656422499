import { Section, SectionHeader } from '@frontend/ui';
import {
  fcfwEventHistoryTableQuery,
  fcfwEventHistoryTableQueryVariables,
} from 'app/apollo/graphql/types';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { workCapabilityMessages } from '../../../assert-work-capability/messages';
import { FCFW_EVENT_HISTORY_TABLE_QUERY } from './graphql/queries';
import { FcfwTable } from './Table';
import { updateQuery } from './update-query';

export const Fcfw: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const suspendRef = useRef(true);

  const [perPage, setPerPage] = useState(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const { data, previousData, fetchMore, loading } = useQuery<
    fcfwEventHistoryTableQuery,
    fcfwEventHistoryTableQueryVariables
  >(FCFW_EVENT_HISTORY_TABLE_QUERY, {
    suspend: suspendRef.current,
    isEmpty: d => !d?.fcfwAssertions?.edges.length,
    errorPolicy: 'all',
    variables: {
      userAccountId,
      companyId,
      first: Number(perPage),
    },
    onCompleted: () => {
      suspendRef.current = false;
    },
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;

  if (!_data?.fcfwAssertions?.edges.length) {
    return null;
  }

  const { fcfwAssertions } = _data;
  const { pageInfo } = fcfwAssertions;

  const navigation = (
    <TableNavigation
      perPage={perPage}
      setPerPage={setPerPage}
      pageInfo={pageInfo}
      onNextPage={() => {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
          updateQuery,
        });
      }}
      onPreviousPage={() => {
        fetchMore({
          variables: {
            before: pageInfo.startCursor,
            first: undefined,
            last: Number(perPage),
          },
          updateQuery,
        });
      }}
    />
  );

  return (
    <>
      {!suspendRef.current && loading && <TopLoading />}
      <Section>
        <SectionHeader>
          <FormattedMessage
            {...workCapabilityMessages.certificationOfFullWorkCapability}
          />
        </SectionHeader>
        <FcfwTable fcfwAssertions={fcfwAssertions} navigation={navigation} />
      </Section>
    </>
  );
};
