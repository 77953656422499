import { EuroAccidentPlansjuk } from '@frontend/benefit-types';
import { DescriptionList, DescriptionListEntry } from '@frontend/ui';
import {
  BenefitPackage,
  FlexEntitlementRules,
  ManagingCompany,
  MiscConfiguration,
  RemunerationType,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';

import { InBenefitPackage } from '../../../components/BenefitsTable/InBenefitPackage';
import {
  benefitDisabilityLevelMessages,
  benefitFormMessages,
} from '../../../form/messages';

interface Benefit {
  configuration: MiscConfiguration;
  entitlementRules: Pick<FlexEntitlementRules, 'benefitPackageIds'> | null;
  id: string;
  type: string;
}

interface Props {
  benefit: EuroAccidentPlansjuk<Benefit>;
  benefitPackageOptions: Pick<BenefitPackage, 'id' | 'name'>[];
  managedBy: ManagingCompany;
  remunerationTypes: readonly RemunerationType[];
}

export const SicknessBenefit: React.FC<Props> = ({
  benefit,
  benefitPackageOptions,
  managedBy,
  remunerationTypes,
}) => {
  const { configuration } = benefit;

  if (!configuration?.data) {
    return null;
  }

  const {
    extendedSickPay,
    isActiveOnParentalLeave,
    level,
    payrollElementCode,
    payrollElementMultiplier,
  } = configuration.data;

  const pensionPayrollElement = remunerationTypes.find(
    type => type.id === payrollElementCode,
  );

  const isManagedByNode = managedBy === 'NODE';

  const entries: DescriptionListEntry[] = [
    {
      id: 'benefit.insuranceLevel',
      label: <FormattedMessage {...commonBenefitMessages.level} />,
      value: level ? (
        <FormattedMessage
          messages={benefitDisabilityLevelMessages}
          select={level}
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.payrollElement',
      label: (
        <FormattedMessage {...commonBenefitMessages.benefitQualifyingIncome} />
      ),
      value: pensionPayrollElement ? (
        <>
          {pensionPayrollElement.id} {pensionPayrollElement.name ?? ''}
        </>
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.annualSalaryFactor',
      label: <FormattedMessage {...commonBenefitMessages.annualFactor} />,
      value: payrollElementMultiplier ?? <NoValue />,
    },
    ...(isManagedByNode
      ? [
          {
            id: 'benefit.parentalLeave',
            label: (
              <FormattedMessage {...commonBenefitMessages.parentalLeave} />
            ),
            value:
              isActiveOnParentalLeave != null ? (
                isActiveOnParentalLeave ? (
                  <FormattedMessage {...commonMessages.yes} />
                ) : (
                  <FormattedMessage {...commonMessages.no} />
                )
              ) : (
                <NoValue />
              ),
          },
        ]
      : []),
    {
      id: 'benefit.extendedSickPay',
      label: <FormattedMessage {...benefitFormMessages.extendedSickPayLabel} />,
      value:
        extendedSickPay != null ? (
          extendedSickPay ? (
            <FormattedMessage {...commonMessages.yes} />
          ) : (
            <FormattedMessage {...commonMessages.no} />
          )
        ) : (
          <NoValue />
        ),
    },
    {
      id: 'benefit.inBenefitPackage',
      label: <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />,
      value: (
        <InBenefitPackage
          benefit={benefit}
          benefitPackageOptions={benefitPackageOptions}
        />
      ),
    },
  ];

  return <DescriptionList alignLeft entries={entries} />;
};
